import { jsx as _jsx } from "react/jsx-runtime";
import SvgColor from '../../../components/svg-color';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { ic_operators, ic_settings, ic_lock, ic_calendar, ic_stat_client_response, ic_call, ic_stats_client, ic_analytics, ic_todolist, ic_offices, ic_notes, ic_finance, ic_file, ic_watch, ic_configuration, } from '../../../assets/icons/navbar';
import { MethodCode, ModelCode } from '../../../@types/Permission';
var icon = function (iconSrc) { return _jsx(SvgColor, { src: iconSrc, sx: { width: 1, height: 1 } }); };
var ICONS = {
    operators: icon(ic_operators),
    settings: icon(ic_settings),
    groupPermissions: icon(ic_lock),
    calendar: icon(ic_calendar),
    calls: icon(ic_call),
    statsClient: icon(ic_stats_client),
    statClientResponse: icon(ic_stat_client_response),
    analytics: icon(ic_analytics),
    todoList: icon(ic_todolist),
    offices: icon(ic_offices),
    notes: icon(ic_notes),
    finances: icon(ic_finance),
    file: icon(ic_file),
    watch: icon(ic_watch),
    configuration: icon(ic_configuration),
};
export var navBarConfigItem = [
    {
        subheader: '',
        items: [
            {
                title: 'Analytics',
                path: PATH_DASHBOARD.analytics,
                icon: ICONS.analytics,
                permission: [
                    {
                        model: ModelCode.ANALYTICS,
                        method: MethodCode.LIST,
                    },
                ],
            },
            {
                title: 'finances',
                path: PATH_DASHBOARD.finances.codeList,
                icon: ICONS.finances,
                permission: [
                    {
                        model: ModelCode.CODE,
                        method: MethodCode.LIST,
                    },
                    {
                        model: ModelCode.OPERATION,
                        method: MethodCode.LIST,
                    },
                    {
                        model: ModelCode.CASH_DESK_PAYMENT,
                        method: MethodCode.LIST,
                    },
                ],
                children: [
                    {
                        title: 'expenses',
                        path: PATH_DASHBOARD.finances.expensesList,
                        permission: {
                            model: ModelCode.OPERATION,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'codes',
                        path: PATH_DASHBOARD.finances.codeList,
                        permission: {
                            model: ModelCode.CODE,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'general cash register',
                        path: PATH_DASHBOARD.finances.generalCashList,
                        permission: {
                            model: ModelCode.CASH_DESK_PAYMENT,
                            method: MethodCode.LIST,
                        },
                    },
                ],
            },
            {
                title: 'Stats client Answers',
                path: PATH_DASHBOARD.statClientResponse.root,
                icon: ICONS.statClientResponse,
                permission: {
                    model: ModelCode.STAT_CLIENT_RESPONSE,
                    method: MethodCode.LIST,
                },
            },
            {
                title: 'calls',
                path: PATH_DASHBOARD.calls,
                icon: ICONS.calls,
                permission: {
                    model: ModelCode.CALLS,
                    method: MethodCode.LIST,
                },
            },
            {
                title: 'calendar',
                path: PATH_DASHBOARD.calender,
                icon: ICONS.calendar,
                permission: {
                    model: ModelCode.MY_WORKTIME,
                    method: MethodCode.LIST,
                },
            },
            {
                title: 'session',
                path: PATH_DASHBOARD.session.root,
                icon: ICONS.watch,
                permission: {
                    model: ModelCode.SESSION,
                    method: MethodCode.LIST,
                },
            },
            {
                title: 'Todo List',
                path: PATH_DASHBOARD.todos.root,
                icon: ICONS.todoList,
                permission: {
                    model: ModelCode.TODO,
                    method: MethodCode.LIST,
                },
            },
            {
                title: 'notes',
                path: PATH_DASHBOARD.notes.root,
                icon: ICONS.notes,
                permission: {
                    model: ModelCode.NOTE,
                    method: MethodCode.LIST,
                },
            },
            {
                title: 'File Manager',
                path: PATH_DASHBOARD.folder.root,
                icon: ICONS.file,
                permission: {
                    model: ModelCode.FOLDER,
                    method: MethodCode.LIST,
                },
            },
            {
                title: 'configuration',
                path: PATH_DASHBOARD.configuration.bounusAmount.root,
                icon: ICONS.configuration,
                permission: [
                    {
                        model: ModelCode.CONFIG_BONUS_AMOUNT,
                        method: MethodCode.LIST,
                    },
                    {
                        model: ModelCode.OFFICE,
                        method: MethodCode.LIST,
                    },
                    {
                        model: ModelCode.KPI,
                        method: MethodCode.LIST,
                    },
                    {
                        model: ModelCode.STAT_CLIENT,
                        method: MethodCode.LIST,
                    },
                    {
                        model: ModelCode.USER,
                        method: MethodCode.LIST,
                    },
                    {
                        model: ModelCode.PERMISSION_GROUP,
                        method: MethodCode.LIST,
                    },
                ],
                children: [
                    {
                        title: 'offices',
                        path: PATH_DASHBOARD.offices.root,
                        permission: {
                            model: ModelCode.OFFICE,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'KPIS',
                        path: PATH_DASHBOARD.kpis.root,
                        permission: {
                            model: ModelCode.KPI,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'stats client',
                        path: PATH_DASHBOARD.statsClient.root,
                        permission: {
                            model: ModelCode.STAT_CLIENT,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'operators',
                        path: PATH_DASHBOARD.operators.root,
                        permission: {
                            model: ModelCode.USER,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'group permissions',
                        path: PATH_DASHBOARD.groupPermissions,
                        permission: {
                            model: ModelCode.PERMISSION_GROUP,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'bonus amount',
                        path: PATH_DASHBOARD.configuration.bounusAmount.root,
                        permission: {
                            model: ModelCode.CONFIG_BONUS_AMOUNT,
                            method: MethodCode.LIST,
                        },
                    },
                    {
                        title: 'receipt payment',
                        path: PATH_DASHBOARD.configuration.receiptPayment.root,
                        permission: {
                            model: ModelCode.PDF_CONTENT,
                            method: MethodCode.VIEW,
                        },
                    },
                ],
            },
        ],
    },
];
export var NAV_BAR_ITEM_WHEN_NOT_AUTHORIZED = [
    {
        subheader: '',
        items: [
            {
                title: 'calendar',
                path: PATH_DASHBOARD.calender,
                icon: ICONS.calendar,
                permission: {
                    model: ModelCode.MY_WORKTIME,
                    method: MethodCode.LIST,
                },
            },
        ],
    },
];
